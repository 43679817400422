@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:focus-visible {
		@apply z-50;
		@apply outline-4;
		@apply outline-yellow-400;
		@apply outline-offset-4;
		box-shadow: 0 0 0 4px #000000;
	}

	.focus-visible {
		@apply z-50;
		@apply outline-[3px];
		@apply outline-yellow-400;
		@apply outline-offset-[3px];
		box-shadow: 0 0 0 3px #000000;
	}

	.focus-link {
		@apply focus-visible:bg-yellow-400;
		@apply focus-visible:shadow-none;
		@apply focus-visible:decoration-4;
		@apply focus-visible:outline-none;
		@apply focus-visible:underline-offset-[0.2em];
	}
}

@layer utilities {
	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}
	.no-scrollbar {
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
}

body {
	background-image: url('/background-wide.svg');
	background-size: cover;
	background-position: 0% 10%;
	background-attachment: fixed;
}

.grecaptcha-badge {
	visibility: hidden;
}

@media (min-aspect-ratio: 16/9) {
	.video-bg {
		/* height = 100 * (9 / 16) = 56.25 */
		height: 56.25vw;
	}
}

@media (max-aspect-ratio: 16/9) {
	.video-bg {
		/* width = 100 / (9 / 16) = 177.777777 */
		width: 177.78vh;
	}
}

input[type="search"] {
	-webkit-appearance: none;
	-webkit-border-radius: 3px;
	border-radius: 3px;
}
